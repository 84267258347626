import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';

import Service from './ads.service';
import { useCookies } from 'react-cookie'
import Header from '../header/header.component';
import { useDropzone } from 'react-dropzone';
import Config from "../../config/config";

interface FileUploadProps { }

const Ads = () => {
    const [session] = useCookies(['access_token_session']);
    const [files, setFiles] = useState<string[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [error, setError] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const onSearchResult = useCallback(
        (textSearch: String) => {

        }, []);

    // Fetch all files from server
    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = async () => {
        try {
            //   const response = 

            //   await axios.get<{ files: string[] }>('http://localhost:3000/files/all');
            Service.getAll(session.access_token_session).then((response) => {
                // setListGrammar(res.data.grammarData);
                setFiles(response.data.files);
            }).catch(err => {
                console.log(err)
            })

        } catch (error) {
            console.error('Error fetching files:', error);
        }
    };

    // const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    //     setSelectedFiles(event.target.files);
    // };

    const handleFileUpload = async () => {
        if (!selectedFiles) {
            setError('Vui lòng chọn file để upload');
            return;
        }



        let isError = false;
        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('files', selectedFiles[i]);

            const image = new Image();
            const fileURL = URL.createObjectURL(selectedFiles[i]);

            image.src = fileURL;

            const checkImageSize = () => {
                return new Promise<void>((resolve, reject) => {
                    image.onload = () => {
                        // Kiểm tra nếu width = 2 * height
                        if (image.width !== 2 * image.height) {
                            setError(`Hình ảnh ${selectedFiles[i].name} không có tỉ lệ chiều rộng gấp đôi chiều cao.`);
                            reject();
                        } else {
                            resolve();
                        }
                    };

                    image.onerror = () => {
                        setError(`Không thể tải tấm hình ${selectedFiles[i].name}`);
                        reject();
                    };
                });
            };

            try {
                await checkImageSize();  // Chờ kiểm tra xong kích thước ảnh
            } catch {
                isError = true;
                return;  // Ngừng quá trình upload nếu có lỗi
            }
        }
        if (isError) {
            return;
        }

        try {
            Service.uploadFile(session.access_token_session, formData).then((response) => {
                setMessage(response.data.message);
                setError('');
                fetchFiles();
                setSelectedFiles([]); // Refresh file list
            }).catch(err => {
                console.log(err)
            })


        } catch (error: any) {
            setError(error.response?.data?.message || 'Upload thất bại');
            setMessage('');
        }
    };

    const handleFileDelete = async (filename: string) => {
        try {
            // await axios.delete(`http://localhost:3000/files/${filename}`);
            Service.deleteFile(session.access_token_session, filename).then(() => {
                fetchFiles();
            });
            // Refresh file list after deletion
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    };
    const onDrop = (acceptedFiles: File[]) => {
        setSelectedFiles(acceptedFiles);
        setError('');
        setMessage('');
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div>
            <Header title={'90 câu ngữ pháp topik I'} pageActive={'GRAMMAR'} textSearch={'90 câu ngữ pháp...'} onSearchResult={onSearchResult} />
            <div style={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'row', marginTop: 50 }}>
                <div style={{ padding: '20px' }}>
                    <h2>Upload Files</h2>

                    <div
                        {...getRootProps()}
                        style={{
                            border: '2px dashed #cccccc',
                            padding: '20px',
                            textAlign: 'center',
                            backgroundColor: isDragActive ? '#f0f0f0' : '#fafafa',
                            cursor: 'pointer',
                        }}
                    >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Drop the files here...</p>
                        ) : (
                            <p>Drag & drop some files here, or click to select files. Please enter image size width = 2 * height</p>
                        )}
                    </div>
                    <button onClick={handleFileUpload} className="btn btn-success">Upload</button>

                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {message && <p style={{ color: 'green' }}>{message}</p>}


                </div>
                <div style={{ padding: '20px' }}>
                    <h3>Files List</h3>
                    <ul>
                        {files.length > 0 ? (
                            files.map((file, index) => (
                                <li key={index}>
                                    <img
                                        src={`${Config.domain + Config.API_URL.ADS.UPDATE + file}`}
                                        alt={file}
                                        style={{ width: '350px', height: 'auto', marginRight: '10px' }}
                                    />
                                    <button onClick={() => handleFileDelete(file)} style={{ marginLeft: '10px' }} className="btn btn-danger">
                                        Delete
                                    </button>
                                </li>
                            ))
                        ) : (
                            <li>No files uploaded yet</li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Ads;
