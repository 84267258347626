import { useState, useCallback, useEffect } from "react";
import Config from '../../config/config';
import { useCookies } from 'react-cookie'
import Service from './grammar.service';
import { GrammarDTO, GrammarInsertDTO } from './grammar.dto';
import Autocomplete from 'react-autocomplete';
import Header from '../header/header.component';
import './grammar.css';
import CKEditor from 'ckeditor4-react';
import readXlsxFile from 'read-excel-file';
import CusMotal from '../header/modal.component';

const Grammar = () => {
    const [listTopic, setListTopic]: any = useState([]);
    const [listGrammar, setListGrammar] = useState<GrammarDTO[]>([]);
    const [grammarEdit, setGrammarEdit] = useState<GrammarDTO | null>(null);
    const [topicSelect, setTopicSelect]: any = useState(null);
    const [selectIndex, setSelectIndex] = useState(-1);
    const [countTotal, setCountTotal] = useState(0);
    const [lang, setLang] = useState<String>('');

    const [isShow, setIsShow] = useState(false);
    const [message, setMessage] = useState('');
    const [refresh, setRefresh] = useState(true)
    const [session] = useCookies(['access_token_session']);
    const [hasSearch, setHasSearch] = useState(false);

    useEffect(() => {
        CKEditor.editorConfig = function (config: any) {
            config.toolbar = Config.CKEDITOR;
        };
    }, []);

    useEffect(() => {
        Service.getGrammar(session.access_token_session, 0, lang).then((res) => {
            setListGrammar(res.data.grammarData);
            setCountTotal(res.data.countTotal);
        }).catch(err => {
            console.log(err)
        })

        Service.getTopic(session.access_token_session, lang).then((res) => {
            setListTopic(res.data);
        }).catch(err => {
            console.log(err)
        })
    }, [session, refresh, lang])

    const handlePageChange = useCallback(
        () => {
            if (listGrammar.length < countTotal && window.innerHeight + document.documentElement.scrollTop === document.scrollingElement?.scrollHeight) {
                Service.getGrammar(session.access_token_session, listGrammar.length, lang).then((res) => {
                    let mList = listGrammar.slice(0, listGrammar.length);
                    mList.push(...res.data.grammarData);
                    setListGrammar(mList);
                }).catch(err => {

                })
            }
        }, [session.access_token_session, listGrammar, countTotal])

    useEffect(() => {
        window.addEventListener('scroll', handlePageChange);
        return (() => {
            window.removeEventListener('scroll', handlePageChange);
        })
    }, [handlePageChange]);

    const clearControl = useCallback(
        () => {
            setGrammarEdit(null);
            setTopicSelect(null);
            setSelectIndex(-1);
        }, []
    )

    const deleteGrammarCallback = useCallback(
        (id: String) => {
            if (window.confirm('Bạn có muốn xóa chuyên ngành này?')) {
                Service.deleteGrammar(session.access_token_session, id).then((res) => {
                    if (res.status === 200) {
                        setIsShow(true);
                        setMessage('Xóa ngữ pháp thành công');
                        setRefresh(!refresh)
                    }
                }).catch(err => {
                    setIsShow(true);
                    setMessage('Xóa ngữ phấp thất bại');
                })
            }
        }, [refresh, session]);

    const updateGrammarCallback = useCallback(
        () => {
            setTopicSelect(null);

            if (grammarEdit?._id === '')
                Service.createGrammar(session.access_token_session, grammarEdit).then((res) => {
                    if (res.status === 201) {
                        setIsShow(true);
                        setMessage('Thêm ngữ pháp thành công');
                        setRefresh(!refresh);
                        clearControl();
                    }
                }).catch(err => {
                    console.log(err)
                    setIsShow(true);
                    setMessage('Thêm ngữ pháp thất bại');
                })
            else Service.updateGrammar(session.access_token_session, grammarEdit, grammarEdit?._id).then((res) => {
                if (res.status === 200) {
                    setMessage('Cập nhật ngữ pháp thành công');
                    setIsShow(true);
                    setRefresh(!refresh);
                    clearControl();
                }
            }).catch(err => {
                setIsShow(true);
                setMessage('Cập nhật ngữ phấp thất bại');
            })
        }, [refresh, session, grammarEdit, clearControl]);

    const createGrammarCallback = useCallback(
        () => {
            if (grammarEdit != null)
                return;
            if (listTopic.length === 0) {
                alert('Tạo topic trước');
                return;
            }
            let mList = [...listGrammar];
            const data = {
                _id: '',
                grammarOfTopic: listTopic[0]._id,
                grammarName: '', //Tu vung
                grammarStruct: '',        //cấu trúc
                grammarExplain: '',    //Chu giai
                grammarContent: '',
                grammarRating: 3,
                grammarRefer: '',    //tham khảo
                grammarReferDetail: '',
                status: true,
                lang: Array.isArray(lang) && lang.length > 0 ? lang[0] : lang,
            };
            mList.unshift(data);
            setListGrammar(mList);
            setGrammarEdit(data);
            setSelectIndex(0);
            setTopicSelect(listTopic[0].topicName);
        }, [grammarEdit, listGrammar, listTopic, lang]);

    const allowUpdateValue = useCallback(
        (index: number) => {
            if (grammarEdit == null) {
                setGrammarEdit(listGrammar[index]);
                setTopicSelect(listGrammar[index].grammarOfTopic.topicName);
                setSelectIndex(index);
            }
        }, [listGrammar, grammarEdit]
    )

    const cancelUpdateValue = useCallback(
        (index: number) => {
            if (grammarEdit != null) {
                clearControl();
                if (listGrammar[0]._id === '') {
                    const mList = listGrammar.splice(1, listGrammar.length);
                    setListGrammar(mList);
                }
            }
        }, [grammarEdit, listGrammar, clearControl]
    )

    const updateValueRef = useCallback(
        (index: number, value: String) => {
            setGrammarEdit((prev: any) => {
                return {
                    ...prev,
                    grammarRefer: value
                }
            });
        }, []
    )

    const updateValueStruct = useCallback(
        (index: number, value: String) => {
            setGrammarEdit((prev: any) => {
                return {
                    ...prev,
                    grammarStruct: value,
                    grammarName: value
                }
            });
        }, []
    )

    const updateValueExplain = useCallback(
        (index: number, value: String) => {
            setGrammarEdit((prev: any) => {
                return {
                    ...prev,
                    grammarExplain: value
                }
            });
        }, []
    )

    const updateValueRating = useCallback(
        (index: number, value: String) => {
            setGrammarEdit((prev: any) => {
                return {
                    ...prev,
                    grammarRating: value
                }
            });
        }, []
    )

    const renderMovieTitle = (state: any, val: any) => {
        if (state == null || val == null) return false;
        return (
            state.topicName.toLowerCase().indexOf(val.toLowerCase()) !== -1
        );
    }

    const readExcel = useCallback((event: any) => {
        readXlsxFile(event.target.files[0], { sheet: 'Grammar' }).then((rows: any) => {
            console.log(rows);
            if (!!rows) {
                let Grammars: any = [];
                rows.map((x: any, index: number) => {
                    if (index !== 0) {
                        const grammar: GrammarInsertDTO = {
                            grammarOfTopic: (x[2] && x[2].toString()) || null,
                            grammarId: (x[3] && x[3].toString()) || null,
                            grammarName: (x[4] && x[4].toString()) || null, //Tu vung
                            grammarStruct: (x[4] && x[4].toString()) || null,        //cấu trúc
                            grammarExplain: (x[5] && x[5].toString()) || null,
                            grammarRating: (x[6] && Number.parseInt(x[6].toString())) || null,
                            grammarContent: (x[7] && x[7].toString()) || null,
                            grammarRefer: (x[8] && x[8].toString()) || null,
                            grammarReferDetail: (x[9] && x[9].toString()) || null,
                            status: true,
                            lang: Array.isArray(lang) && lang.length > 0 ? lang[0] : lang,
                        }
                        Grammars.push(grammar);
                    }
                    return null;
                })
                Service.createManyGrammar(session.access_token_session, Grammars).then((res) => {
                    if (res.status === 201) {
                        const success: number = res.data.filter((item: any) => {
                            if (item == null) return false;
                            else return true;
                        }).length;
                        setIsShow(true);
                        setMessage('Thêm thành công ' + success + ' câu ngữ pháp, thất bại ' + (res.data.length - success) + ' câu ngữ pháp');
                        setRefresh(!refresh)
                    }
                })
            }
        })
    }, [refresh, session, lang]);

    const onSearchResult = useCallback(
        (textSearch: String) => {
            if (textSearch !== '') {
                Service.searchGrammar(textSearch, lang).then(res => {
                    setListGrammar(res.data);
                }).catch(err => { });
                setHasSearch(true);
            } else {
                if (hasSearch) {
                    setRefresh(!refresh)
                    setHasSearch(false);
                }
            }
        }, [refresh, lang, hasSearch]);

    const onChangeLanguage = useCallback(
        (language: String) => {

            if (language != lang) {
                console.log("change language", language);
                setLang(language);
            }

        }, [lang]);

    return (
        <div>
            <Header title={'Quản lý từ vựng'} pageActive={'GRAMMAR'} textSearch={'ngữ pháp...'} onSearchResult={onSearchResult} onChangeLanguage={onChangeLanguage} />
            <table className="table" style={{ marginTop: 50 }}>
                <thead >
                    <tr>
                        <th scope="col" style={{ width: '5%', textAlign: 'center' }}>STT</th>
                        <th scope="col" style={{ width: '18%', textAlign: 'center' }}>Cấu trúc</th>
                        <th scope="col" style={{ width: '5%', textAlign: 'center' }}>Khó</th>
                        <th scope="col" style={{ width: '10%', textAlign: 'center' }}>Chủ đề</th>
                        <th scope="col" style={{ width: '20%', textAlign: 'center' }}>Nội dung</th>
                        <th scope="col" style={{ width: '10%', textAlign: 'center' }}>Tham khảo(Ref)</th>
                        <th scope="col" style={{ width: '17%', textAlign: 'center' }}>Nội dung(Ref)</th>
                        <th scope="col" style={{ width: '15%', textAlign: 'center' }}>
                            <button type="button" className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => { createGrammarCallback() }}>+</button>
                            <button type="button" className="btn btn-success btn-file" style={{ marginLeft: 10 }}>
                                <input type="file" id="input" onChange={readExcel} />
                                Nhập Excel
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listGrammar.map((x: GrammarDTO, index: number) => {
                            return (
                                <tr key={x._id.toString()} style={{ backgroundColor: '#FFFFFF' }}>
                                    {
                                        index === selectIndex && grammarEdit != null ?
                                            <>
                                                <td colSpan={7}>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ flex: 1 }}>
                                                            <strong>+ Cấu trúc: </strong><input style={{ marginBottom: 5 }} className="form-control" placeholder="Nhập cấu trúc" value={grammarEdit.grammarStruct.toString()} onChange={(event) => updateValueStruct(index, event.target.value)} />
                                                            <strong>+ Chú thích:</strong> <input style={{ marginBottom: 5 }} className="form-control" placeholder="Nhập chú thích" value={grammarEdit.grammarExplain.toString()} onChange={(event) => updateValueExplain(index, event.target.value)} />
                                                            <strong>+ Độ khó: </strong><input style={{ marginBottom: 5 }} className="form-control" placeholder="Nhập độ khó bằng số" value={grammarEdit.grammarRating.toString()} onChange={(event) => updateValueRating(index, event.target.value)} />
                                                        </div>
                                                        <div style={{ flex: 1, marginLeft: 10 }}>
                                                            <strong>+ Tham khảo:</strong> <input style={{ marginBottom: 5 }} className="form-control" placeholder="Nhập từ vựng hoặc câu từ" value={grammarEdit.grammarRefer && grammarEdit.grammarRefer.toString()} onChange={(event) => updateValueRef(index, event.target.value)} />
                                                            <strong>+ Chuyên ngành:</strong> <div className={'autocomplete-wrapper'}>
                                                                <Autocomplete
                                                                    value={topicSelect}
                                                                    items={listTopic}
                                                                    getItemValue={item => {
                                                                        setGrammarEdit((prev: any) => {
                                                                            return {
                                                                                ...prev,
                                                                                grammarOfTopic: item._id
                                                                            }
                                                                        });
                                                                        return item.topicName
                                                                    }}
                                                                    shouldItemRender={renderMovieTitle}
                                                                    renderMenu={item => (
                                                                        <div className="dropdown">
                                                                            {item}
                                                                        </div>
                                                                    )}
                                                                    renderItem={(item, isHighlighted) =>
                                                                        <div key={item._id} className={`item ${isHighlighted ? 'selected-item' : ''}`}>
                                                                            {item.topicName}
                                                                        </div>
                                                                    }
                                                                    onChange={(event, val: any) => {
                                                                        console.log(val);
                                                                        setTopicSelect(val)
                                                                    }}
                                                                    onSelect={(val: any) => {
                                                                        setTopicSelect(val)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ flex: 1 }}>
                                                            <strong>+ Nội dung cấu trúc:</strong>
                                                            <CKEditor
                                                                data={grammarEdit.grammarContent}
                                                                onInit={(editor: any) => {
                                                                    editor.editing.view.change((writer: any) => {
                                                                        writer.setStyle(
                                                                            "height",
                                                                            "300px",
                                                                            editor.editing.view.document.getRoot()
                                                                        );
                                                                    });
                                                                }}
                                                                onChange={(event: any) => {
                                                                    const data = event.editor.getData()
                                                                    setGrammarEdit((prev: any) => {
                                                                        return {
                                                                            ...prev,
                                                                            grammarContent: data
                                                                        }
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                        <div style={{ flex: 1, marginLeft: 10 }}>
                                                            <strong>+ Nội dung tham khảo:</strong>
                                                            <CKEditor
                                                                data={grammarEdit.grammarReferDetail}
                                                                onInit={(editor: any) => {
                                                                    editor.editing.view.change((writer: any) => {
                                                                        writer.setStyle(
                                                                            "height",
                                                                            "300px",
                                                                            editor.editing.view.document.getRoot()
                                                                        );
                                                                    });
                                                                }}
                                                                onChange={(event: any) => {
                                                                    const data = event.editor.getData()
                                                                    setGrammarEdit((prev: any) => {
                                                                        return {
                                                                            ...prev,
                                                                            grammarReferDetail: data
                                                                        }
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </>
                                            :
                                            <>
                                                <th scope="row" style={{ textAlign: 'center' }}>{index + 1}</th>
                                                <td style={{ textAlign: 'center' }}>{x.grammarName}</td>
                                                <td style={{ textAlign: 'center' }}>{x.grammarRating}</td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {x.grammarOfTopic?.topicName} <br /> Topik {x.grammarOfTopic?.topik}
                                                </td>
                                                <td><div style={{ maxHeight: 220, overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: x.grammarContent?.toString() }} /></td>
                                                <td style={{ textAlign: 'center' }}>{x.grammarRefer}</td>
                                                <td><div style={{ maxHeight: 220, overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: x.grammarReferDetail?.toString() }} /></td>
                                            </>
                                    }

                                    <td style={{ textAlign: 'center' }}>
                                        {
                                            index === selectIndex && grammarEdit != null ?
                                                <div>
                                                    <button type="button" className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => updateGrammarCallback()}>Xác nhận</button>
                                                    <button type="button" className="btn btn-danger" style={{ marginLeft: 10 }} onClick={() => cancelUpdateValue(index)}>Hủy</button>
                                                </div>
                                                :
                                                <div>
                                                    <button type="button" className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => allowUpdateValue(index)}>Sửa</button>
                                                    <button type="button" className="btn btn-danger" style={{ marginLeft: 10 }} onClick={() => deleteGrammarCallback(x._id.toString())}>Xóa</button>
                                                </div>
                                        }
                                    </td>
                                </tr>
                            );
                        })
                    }

                </tbody>
            </table>
            <CusMotal
                isShow={isShow}
                okText={'OK'}
                cancelText={null}
                message={message}
                title={'Thông Báo'}
                onOK={() => {
                    setIsShow(false);
                }}
                onClose={() => setIsShow(false)}
            />
        </div>
    );
}
export default Grammar;